import React, { useEffect, useState, useRef, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link, useNavigate } from "react-router-dom"
import LoginRedirect from '../shared-components/LoginRedirect'
import ListAssets from '../asset-management/ListAssets'
import Spinner from '../ui-elements/Spinner'
import Card from '../ui-elements/Card'
import Button from '../formfields/Button'
import ProgressBar from '../formfields/ProgressBar'
import { subscribe } from "../utils/pubsub"
import { dateDiff } from "../utils/datediff"
import { getDate } from "../utils/getDate"
import FridgeCard from './FridgeCard'



type Props = {

}

type FridgeGroupProps = {
  groupId: string,
  checkTimes: ObjectStringKeyAnyValue | null
}


function FridgeGroup({
  groupId,
  checkTimes,
}: FridgeGroupProps) {




  const {
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)

  const [timeDifference, setTimeDifference] = useState<string | null>(null)
  const [currentCheck, setCurrentCheck] = useState<ObjectStringKeyAnyValue | null>(null)
  const [currentCheckStart, setCurrentCheckStart] = useState<string | null>(null)
  const [currentCheckFinish, setCurrentCheckFinish] = useState<string | null>(null)
  const [fridgeIdsForThisGroup, setFridgeIdsForThisGroup] = useState<Array<string>>([])
  const [numberOfFridges, setNumberOfFridges] = useState<number | null>(null)
  const [numberOfFridgesChecked, setNumberOfFridgesChecked] = useState<number | null>(null)
  const [numberOfOutOfRangeReadings, setNumberOfOutOfRangeReadings] = useState<number | null>(null)
  const [numberOfNotes, setNumberOfNotes] = useState<number | null>(null)



  useEffect(() => {
    filterCheckTimeWindows()
  }, [tableData, checkTimes])

  useEffect(() => {
    filterFridges()
  }, [fridges])

  useEffect(() => {
    filterReadings()
  }, [fridgeIdsForThisGroup])

  const getTimeFromDate = (dateString: string) => {
    let modifiedString = ''
    modifiedString = dateString.split(' ')[1]
    modifiedString = modifiedString.substring(0, 5)
    return modifiedString
  }

  const formatTimeDifference = (dateDifference: number) => {
    let formattedString = ``
    let hours = Math.floor(dateDifference / 60)
    let minutes = Math.round(dateDifference - (hours * 60))
    if (hours > 0) {
      formattedString = `${hours} hours ${minutes} minutes`
    } else {
      formattedString = `${minutes} minutes`
    }
    return formattedString
  }

  const filterCheckTimeWindows = () => {
    // console.log(`🥕 `)
    if (checkTimes && Object.keys(checkTimes).length > 0 && checkTimes[groupId]) {
      //console.log(`filter the checks`)
      let currentCheckData: ObjectStringKeyAnyValue = {}
      currentCheckData = checkTimes[groupId].current_check
      setCurrentCheck(currentCheckData)
      const check_window_start = currentCheckData.we_are_in_a_check ? currentCheckData.check_started_at : currentCheckData.last_check_started_at
      const check_window_end = currentCheckData.we_are_in_a_check ? currentCheckData.check_finishes_at : currentCheckData.last_check_finished_at
      let check_window_start_obj = new Date(check_window_start)
      let check_window_end_obj = new Date(check_window_end)
      let check_window_start_unix = `${check_window_start_obj.getTime()}`
      let check_window_end_unix = `${check_window_end_obj.getTime()}`
      const rightNow = Date.now()
      const dateDifference = dateDiff(rightNow, check_window_end_unix, 'fullDifference')
      setTimeDifference(`${dateDifference}`)
      setCurrentCheckStart(check_window_start_unix)
      setCurrentCheckFinish(check_window_end_unix)
    }
  }

  const filterFridges = () => {
    const fridgeIdArray: string[] = []
    if (fridges) {
      //console.log(`🍠 filtering the fridges`)
      Object.keys(fridges).forEach((fridgeId) => {
        if (fridges[fridgeId].GroupId === groupId) {
          fridgeIdArray.push(fridgeId)
        }
      })
      setFridgeIdsForThisGroup(fridgeIdArray)
      setNumberOfFridges(fridgeIdArray.length)
    }
  }

  const filterReadings = () => {
    //console.log(`🍠 filtering readings`)
    if (tableData && tableData.Assets && tableData.Checks && tableData.AssetProfiles && currentCheckStart && currentCheckFinish && fridgeIdsForThisGroup.length > 0) {
      let newCheckedFridges: string[] = []
      let newNumberOfNotes = 0
      let newNumberOfOutOfRange = 0
      Object.keys(tableData.Checks).forEach((checkId) => {
        let assetIsInThisGroup = false
        let isWithinCurrentCheck = false
        const AssetId = tableData.Checks[checkId].AssetId
        const TimeStamp = tableData.Checks[checkId].TimeStamp
        const ProfileId = tableData.Assets[AssetId].ProfileId
        const Reading = parseInt(`${tableData.Checks[checkId].Reading}`)
        const ExpectedMinTemp = parseInt(`${tableData.AssetProfiles[ProfileId].ExpectedMinTemp}`)
        const ExpectedMaxTemp = parseInt(`${tableData.AssetProfiles[ProfileId].ExpectedMaxTemp}`)
        if (fridgeIdsForThisGroup.includes(AssetId)) {
          assetIsInThisGroup = true
          if (TimeStamp >= currentCheckStart && TimeStamp <= currentCheckFinish) {
            isWithinCurrentCheck = true
            if (Reading < ExpectedMinTemp || Reading > ExpectedMaxTemp) {
              newNumberOfOutOfRange++
            }
            if (assetIsInThisGroup && isWithinCurrentCheck) {
              if (!newCheckedFridges.includes(AssetId)) {
                newCheckedFridges.push(AssetId)
              }
              if (tableData.Checks[checkId].Notes) {
                newNumberOfNotes++
              }
            }
          }
        }
        //console.log(`❌ reading: ${Reading} min: ${ExpectedMinTemp} max: ${ExpectedMaxTemp}`)
        //console.log(`${checkId} - ${assetIsInThisGroup ? 'yes' : 'no'} - ${isWithinCurrentCheck ? 'yes' : 'no'}`)
      })
      setNumberOfFridgesChecked(newCheckedFridges.length)
      setNumberOfNotes(newNumberOfNotes)
      setNumberOfOutOfRangeReadings(newNumberOfOutOfRange)
    } else {
      // console.log(tableData)
      // console.log(tableData!.Assets)
      // console.log(tableData!.Checks)
      // console.log(tableData!.AssetProfiles)
      // console.log(currentCheckFinish)
      // console.log(currentCheckStart)
      // console.log(fridgeIdsForThisGroup)
    }
  }

  return <Card fullwidth={true}>
    {/* <p>{tableData ? 'tableData yes' : 'tableData no'}</p>
    <p>{tableData && tableData.Checks ? 'tableData checks yes' : 'tableData checks no'}</p>
    <p>{currentCheck ? 'currentcheck yes' : 'currentcheck no'}</p>
    <p>{numberOfFridges !== null ? 'numberOfFridges yes' : 'numberOfFridges no'}</p>
    <p>{numberOfFridges}</p>
    <p>{numberOfFridgesChecked !== null ? 'numberOfFridgesChecked yes' : 'numberOfFridgesChecked no'}</p>
    <p>{numberOfOutOfRangeReadings !== null ? 'numberOfOutOfRangeReadings yes' : 'numberOfOutOfRangeReadings no'}</p>
    <p>{numberOfNotes !== null ? 'numberOfNotes yes' : 'numberOfNotes no'}</p>
    <p>{JSON.stringify(fridgeIdsForThisGroup)}</p> */}

    {tableData && currentCheck !== null && numberOfFridges !== null ?
      <div className={`w-full h-full flex flex-col justify-between gap-4`}>
        <div className={`flex flex-col gap-2 text-sm`}>
          <h4 className={`text-lg  font-bold font-righteous text-brandblue`}
          >{tableData.Groups[groupId].GroupName}</h4>
          {numberOfFridges > 0 && numberOfFridgesChecked !== null && <div>
            {fridgeIdsForThisGroup.length > 0 && <div className={`flex flex-col gap-2 text-sm`}>
              {currentCheck.we_are_in_a_check === true ?
                <p>Check period <span className={`font-bold`}>{getTimeFromDate(currentCheck.check_started_at)}</span> - <span className={`font-bold`}>{getTimeFromDate(currentCheck.check_finishes_at)}</span> <br />{timeDifference !== null && `(${timeDifference} remaining)`}</p> :
                <p>Check period <span className={`font-bold`}>{getTimeFromDate(currentCheck.last_check_started_at)}</span> - <span className={`font-bold`}>{getTimeFromDate(currentCheck.last_check_finished_at)}</span> <br />  {timeDifference !== null && ` (Finished ${timeDifference} ago)`}</p>}

              {numberOfFridgesChecked !== numberOfFridges && <ProgressBar
                progress={Math.round(numberOfFridgesChecked / numberOfFridges * 100)}
                variant={currentCheck!.we_are_in_a_check === false && numberOfFridgesChecked !== numberOfFridges ? 'warning' : 'primary'}
              />}

              {currentCheck!.we_are_in_a_check === true && numberOfFridgesChecked !== numberOfFridges && <p>{numberOfFridgesChecked} out of {numberOfFridges} fridges checked</p>}


              {currentCheck.we_are_in_a_check === false && numberOfFridgesChecked !== numberOfFridges && <div className={`flex flex-row gap-2 items-start`}>
                <img src='/red-alert-icon.png' alt='' className={`h-4 mt-1`} />
                <p><span className={`font-bold`}>Incomplete:</span> {numberOfFridgesChecked} out of {numberOfFridges} fridges checked</p>
              </div>}

              {numberOfFridgesChecked === numberOfFridges && <div className={`flex flex-row gap-2 items-start`}>
                <img src='/green-tick-icon.png' alt='' className={`h-4 mt-1`} />
                <p>All {numberOfFridges} fridges checked</p>
              </div>}


              {numberOfNotes !== null && numberOfOutOfRangeReadings !== null && numberOfNotes + numberOfOutOfRangeReadings > 0 && <div className={`flex flex-row gap-2 items-start`}>
                <img src='/red-alert-icon.png' alt='' className={`h-4 mt-1`} />
                <p>
                  {numberOfOutOfRangeReadings > 0 && `${numberOfOutOfRangeReadings} out of range readings`}
                  {numberOfOutOfRangeReadings > 0 && numberOfNotes > 0 && ' and '}
                  {numberOfNotes > 0 && `${numberOfNotes} notes recorded.`}
                </p>
              </div>}
            </div>}
            {fridgeIdsForThisGroup.length === 0 && <p className={`text-sm`}>
              No fridges set up for this group.
              Go to the <Link to='/fridges' className={`underline`}>fridges and freezers</Link> page to set some up.</p>}
            {currentCheck === null && <p className={`text-sm`}>
              No check times set up for this group.
              Go to the <Link to='/groups' className={`underline`}>groups</Link> page to set some up.</p>}
          </div>}

          {numberOfFridges === 0 && <p>
            No fridges assigned to this group. Edit these in your <Link to='/fridges' className={`underline`}>Fridges</Link> page.
          </p>}

        </div>


        {numberOfFridges > 0 && currentCheck !== null && <Button
          internalLinkUrl={`/listreadings/${groupId}`}
          text={'View readings'}
          variant={currentCheck!.we_are_in_a_check === false && numberOfFridgesChecked !== numberOfFridges ? 'warning' : 'primary'}
        />}
      </div>
      :
      <Spinner><p>Loading...</p></Spinner>}
  </Card>
}


function TemperatureLogging({

}: Props) {


  const {
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)

  const [numberOfGroups, setNumberOfGroups] = useState<number | null>(null)
  const [checkTimes, setCheckTimes] = useState<ObjectStringKeyAnyValue | null>(null)
  const navigate = useNavigate()
  const intervalRef = useRef(null);

  useEffect(() => {

    fetchCheckTimesAndChecks()
    //@ts-ignore
    return () => clearInterval(intervalRef.current)
  }, [])

  useEffect(() => {
    return () => {
      //@ts-ignore
      clearInterval(intervalRef.current)
    }
  }, [navigate])


  useEffect(() => {
    if (tableData && tableData.Groups && fridges && numberOfGroups === null) {
      setNumberOfGroups(Object.keys(tableData.Groups).length)
      //@ts-ignore
      intervalRef.current = setInterval(fetchCheckTimesAndChecks, 1000 * 60 * 5)
    }
    if (tableData && fridges && !tableData.Checks) {
      fetchCheckTimesAndChecks()
    }
  }, [tableData, fridges])




  const fetchCheckTimesAndChecks = () => {
    //console.log(`calling fetch...`)
    if (tableData && fridges) {
      //console.log(`running fetch times and checks`)
      let requestCheckingTimes = JSON.stringify({
        action: "fridgetemps",
        fridgeAction: "requestCheckingTimes"
      })
      sendMessageToWebsocket(requestCheckingTimes)
      const unsubscribeTimes = subscribe("returnCheckingTimes", data => {
        //console.log(`✅ check times returned `)
        //console.log(data)
        setCheckTimes(data)
        fetchChecks()
        unsubscribeTimes()
      })
    }
  }

  const fetchChecks = () => {
    //console.log(`calling fetch checks`)
    if (tableData) {
      let assetsToCheck = fridges ? Object.keys(fridges) : []
      let requestChecks = JSON.stringify({
        action: "fetchSingleTableData",
        tableName: "Checks",
        assetsToCheck: assetsToCheck,
        startDate: new Date().setHours(0, 0, 0, 0),
        endDate: new Date().setHours(23, 59, 59, 999)
      })
      //console.log(`assets to check: ${JSON.stringify(assetsToCheck)}`)
      sendMessageToWebsocket(requestChecks)
      const unsubscribeChecks = subscribe("returnSingleTableData", data => {
        //console.log(data)
        unsubscribeChecks()
      })
    }
  }



  return <div
    data-testid={'temperaturelogging'}
    className={`w-full flex flex-col gap-2 mt-2`}>



    {tableData && fridges && Object.keys(fridges).length > 0 && 
    <div className={`w-full flex flex-row gap-2 flex-wrap`}>
      <p>{Object.keys(fridges).length} fridges found</p>
    <div className={`w-full flex flex-row gap-2 flex-wrap`}>
        {fridges && Object.values(fridges).map((fridge: any, index: number) => <FridgeCard
          key={index}
          fridge={fridge}
        />)}
      </div>
      </div>}


    {tableData && (!fridges || (fridges && Object.keys(fridges).length === 0)) && <Card>
      <div className={`w-full flex flex-row flex-wrap items-center gap-4 justify-between`}>
        <p className={`text-sm`}>No fridges or freezers are currently set up.</p>
        <div className={`flex flex-row flex-wrap items-center gap-4`}>
          <Button
            internalLinkUrl={`/add-sticker-pack`}
            text={`Add sticker pack`}
            fullwidth={false}
          />
          <Button
            internalLinkUrl={`/fridges`}
            text={`Add individual fridge`}
            fullwidth={false}
          />
        </div>
      </div>
    </Card>}

    {!tableData && <Spinner><p>Loading...</p></Spinner>}
  </div >
}
export default TemperatureLogging