import React, { useEffect, useState, useContext } from 'react'
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'

import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import DeleteButton from '../formfields/DeleteButton'
import QuestionEditDeleteReposition from './QuestionEditDeleteReposition'

type Props = {
    question: ObjectStringKeyAnyValue
    showEditButton?: boolean,
    questionId: string
    savingProgress: boolean
    setSavingProgress: React.Dispatch<React.SetStateAction<any>>
    isFirst?: boolean
    isLast?: boolean
  }


function IndividualQuestionDetail({
    question,
    questionId,
    showEditButton = true,
    savingProgress,
    setSavingProgress,
    isFirst,
    isLast
}: Props) {

  const {
  } = useContext(GlobalContext)




  return  <div className={`bg-gray-300 w-full rounded flex flex-row gap-3 justify-between`}>
  <div className={`w-full flex flex-col px-2 py-2 text-sm`}>
    <div className={`w-full flex flex-row justify-between gap-2 `}>

      <h4 className={`font-righteous text-brandblue text-lg`}>
        {question['QuestionFormat'] === 'FridgeTemperature' ?
          `Temperature check for fridge${question['FridgeId'] ? `: #${question['FridgeId']}` : ''}` :
          question['QuestionText']}
        {/* : <span className={`font-normal italic text-xs text-gray-600`}> ({questionId})</span> */}
      </h4>


    </div>

    {question['QuestionFormat'] !== 'FridgeTemperature' &&
      <p>{question['QuestionFormat']}</p>}

    {question['QuestionOptions'] && question['QuestionOptions'].length > 0 &&
      <div className={`flex flex-row flex-wrap gap-1`}>
        Options:
        {question['QuestionOptions'].map((option: string, index: number) => {
          return <p key={index}>{option}{(index + 1) < question['QuestionOptions'].length && ','}</p>
        })}
      </div>}

      {question['QuestionInstructions'] &&
      <p>Instructions: {question['QuestionInstructions']}</p>}


      {question['RepeatQuestion'] && question['RepeatQuestion'].length > 0 &&
       <div className={`flex flex-row gap-1`}> 
        <p>Repeat question after:</p>
          {question['RepeatQuestion'].map((option: string, index: number) => {
            return <p key={index}>
              {parseInt(option) < 60 ? `${option} minutes` : `${parseInt(option) / 60} hours`}
              {(index + 1) < question['RepeatQuestion'].length && ','}
            </p>
           })}
          </div>}
 
          {question['SpreadsheetCellReference'] &&
      <p>Custom spreadsheet cell reference: {question['SpreadsheetCellReference']}</p>}

    {!question['QuestionFormat'] &&
      <img src={`/red-alert-icon.png`} className={`w-5 h-5`} alt={''} />}
  </div>



  {showEditButton &&
    <QuestionEditDeleteReposition
      isFirst={isFirst}
      isLast={isLast}
      question={question}
    />
  }


</div>
}
export default IndividualQuestionDetail