import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { useNavigate, Link } from 'react-router-dom'
import { validateForm } from '../utils/validateForm'
import { subscribe } from "../utils/pubsub"
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import Card from '../ui-elements/Card'
import Text from '../formfields/Text'
import Number from '../formfields/Number'
import Button from '../formfields/Button'
import EnabledDisabled from '../formfields/EnabledDisabled'
import Dropdown from '../formfields/Dropdown'
import Checkbox from '../formfields/Checkbox'
import SubNavigation from './SubNavigation'


type ObjectStringKeyAnyValue = {
    [key: string]: any
}

type Props = {

}


function ChecklistOptions({

}: Props) {


    const {
              tableData,
        userData,
        fridges,
        currentOrganisation,
        switchCurrentOrganisation,
        sendMessageToWebsocket
    } = useContext(GlobalContext)


    const [savingProgress, setSavingProgress] = useState(false)
    const [requireSignoff, setRequireSignoff] = useState(false)



    useEffect(() => {
        if (
            tableData && tableData.Organisation &&
            tableData.Organisation.ChecklistOptions &&
            (
                tableData.Organisation.ChecklistOptions['RequireSignoff'] === true ||
                tableData.Organisation.ChecklistOptions['RequireSignoff'] === false
            )
        ) {
            setRequireSignoff(tableData.Organisation.ChecklistOptions['RequireSignoff'])
        }
    }, [tableData])




    const handleChange = (fieldName: string, enabled: boolean) => {
        setRequireSignoff(enabled)
        setSavingProgress(true)
        const payload = JSON.stringify({
            action: "updateChecklistOptions",
            values: {
                "requireSignoff": enabled
            }
        })
        sendMessageToWebsocket(payload)

        const unsubscribeUpdate = subscribe("updateChecklistOptionsResponse", data => {
            console.log(data)
            setSavingProgress(false)
            unsubscribeUpdate()
        })
    }





    return <div className={`flex flex-col items-center`}>
        <SubNavigation
            appName={'Checklists'}
            fullWidthStrip={true}
        />
        <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>
            <h2 className={`text-3xl  font-bold font-righteous text-brandblue`}>
                Checklist Options</h2>


            {tableData && tableData.Organisation ? <div
                className={`w-full flex flex-row items-start gap-5`}>

                Require checklist signoff?

                <EnabledDisabled
                    enabled={requireSignoff}
                    handleChange={handleChange}
                    fieldName={`requireChecklistSignoff`}
                />

            </div>
                :
                <Spinner><p>Loading...</p></Spinner>}



            {savingProgress && <Modal
                showCloseButton={true}
                setShowModal={setSavingProgress}
            ><Spinner><p>Saving...</p></Spinner>
            </Modal>}


        </div>
    </div>
}
export default ChecklistOptions