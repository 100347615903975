import React, { useContext } from 'react'
import { GlobalContext } from '../../GlobalContext'
import RedAlert from '../../ui-elements/RedAlert'
import Button from '../../formfields/Button'
import EnabledDisabled from '../../formfields/EnabledDisabled'


type Props = {
    questionsFormFields: ObjectStringKeyAnyValue
    thisChecklist: ObjectStringKeyAnyValue | null
    questionsFormErrors: ObjectStringKeyAnyValue
    setQuestionsFormFields: React.Dispatch<React.SetStateAction<any>>
    cancelRepeatQuestion: AnyFunction
    showCancelRepeatQuestionButton: boolean
    setQuestionsFormErrors: React.Dispatch<React.SetStateAction<any>>
}

function RepeatQuestion({
    questionsFormFields,
    thisChecklist,
    questionsFormErrors,
    setQuestionsFormFields,
    cancelRepeatQuestion,
    showCancelRepeatQuestionButton,
    setQuestionsFormErrors
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)


    const handleChange = (valueInMinutes: string, enabled: boolean) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...questionsFormFields }
        if (enabled) {
            if (!newFieldValues['RepeatQuestion']){
                newFieldValues['RepeatQuestion'] = [] 
            }
            newFieldValues['RepeatQuestion'].push(`${valueInMinutes}`)
        } else {
            if (newFieldValues['RepeatQuestion']){
                newFieldValues['RepeatQuestion'] = newFieldValues['RepeatQuestion'].filter((item: any) => item !== `${valueInMinutes}`)
            }
        }
        setQuestionsFormFields(newFieldValues)
    }

    const repeatOptions = [
        { valueInMinutes: 15, label: '15 minutes' },
        { valueInMinutes: 30, label: '30 minutes' },
        { valueInMinutes: 60, label: '1 hour' },
        { valueInMinutes: 90, label: '1.5 hours' },
        { valueInMinutes: 120, label: '2 hours' },
        { valueInMinutes: 150, label: '2.5 hours' },
        { valueInMinutes: 180, label: '3 hours' },
        { valueInMinutes: 210, label: '3.5 hours' }
    ]

    return <div className={`flex flex-col gap-2`}>
        <div className={`flex flex-row items-center gap-2 justify-between mb-2`}>
            <h3 className={`font-righteous text-brandblue text-lg`}>Repeat question after:</h3>

            <div className={`flex flex-row items-center gap-2 justify-between mb-2`}>
 
                {showCancelRepeatQuestionButton && <Button
                    onClick={cancelRepeatQuestion}
                    text={`Cancel`}
                    variant={`gray`}
                    fullwidth={false}
                />}
            </div>
        </div>


        <div className={`flex flex-row flex-wrap gap-3 mb-4`}>
            {repeatOptions.map((option: any, index: number) => {
                return <div
                    key={index}
                    className={`flex flex-row gap-2`}
                >
                    <EnabledDisabled
                        enabled={questionsFormFields['RepeatQuestion'] && questionsFormFields['RepeatQuestion'].includes(`${option.valueInMinutes}`)} handleChange={handleChange}
                        fieldName={option.valueInMinutes}
                    />
                    <div className={`w-24`}>{option.label}</div>
                </div>
            })}
        </div>


        {questionsFormErrors['RepeatQuestion'] && <RedAlert alignment='left'>{questionsFormErrors['RepeatQuestion']}</RedAlert>}
    </div>
}



export default RepeatQuestion