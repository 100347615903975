import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link, useNavigate } from "react-router-dom"
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import { subscribe } from "../utils/pubsub"
import DeleteButton from '../formfields/DeleteButton'
import { validateForm } from '../utils/validateForm'
import Button from '../formfields/Button'
import SubNavigation from '../shared-components/SubNavigation'
import RedAlert from '../ui-elements/RedAlert'

type ObjectStringKeyAnyValue = {
    [key: string]: any
}


type Props = {

}







function AddStickerPack({

}: Props) {

    const {
        tableData,
        userData,
        fridges,
        currentOrganisation,
        switchCurrentOrganisation,
        sendMessageToWebsocket
    } = useContext(GlobalContext)

    const navigate = useNavigate()
    const [addOrEditItem, setAddOrEditItem] = useState<string | null>(null)
    const [stickerPackId, setStickerPackId] = useState<string | null>(null)
    const [savingProgress, setSavingProgress] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const minimumIdLength = 4



    const saveItem = () => {
        if (stickerPackId && stickerPackId.length >= minimumIdLength) {
            setSavingProgress(true)
            const payload = JSON.stringify({
                action: "fridgetemps",
                fridgeAction: "addStickerPackToAccount",
                stickerPackId: stickerPackId
            })
            sendMessageToWebsocket(payload)

            const unsubscribe = subscribe("addStickerPackResponse", data => {
                if (data.error) {
                    setErrorMessage(data.error)
                } else {
                    navigate('/dashboard')
                    setErrorMessage(null)
                }
                setSavingProgress(false)
                unsubscribe()
            })
        }
    }


    return <div className={`flex flex-col items-center`}>


        {savingProgress && <Modal
            showCloseButton={true}
            setShowModal={setSavingProgress}
        ><Spinner><p>Adding sticker pack...</p></Spinner>
        </Modal>}


        <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>
            <h2 className={`text-3xl  font-bold font-righteous text-brandblue`}
            >Add Sticker Pack</h2>

            <p>Enter the pack ID below:</p>
            <div className={`w-full flex flex-row gap-4 items-center`}>
            <h2 className={`text-3xl  font-bold `}
            >#</h2>
                <input
                    className={`bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                    type='text'
                    name={'stickerPackId'}
                    value={stickerPackId || ''}
                    onChange={(e) => setStickerPackId(e.target.value)}
                />
                <Button
                    onClick={saveItem}
                    text={'Add to account'}
                    variant={stickerPackId && stickerPackId.length >= minimumIdLength ? 'primary' : 'gray'}
                    fullwidth={false}
                    disabled={stickerPackId && stickerPackId.length >= minimumIdLength ? false : true}
                />
            </div>

            {errorMessage && <RedAlert>{errorMessage}</RedAlert>}
        </div>
    </div>
}
export default AddStickerPack