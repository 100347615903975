import React, { useEffect, useState, useRef, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link, useNavigate } from "react-router-dom"
import LoginRedirect from './LoginRedirect'
import ListAssets from '../asset-management/ListAssets'
import Spinner from '../ui-elements/Spinner'
import Card from '../ui-elements/Card'
import Button from '../formfields/Button'
import ProgressBar from '../formfields/ProgressBar'
import { subscribe } from "../utils/pubsub"
import { dateDiff } from "../utils/datediff"
import { getDate } from "../utils/getDate"



type Props = {
  appName: string
  expired?: boolean
  fullWidthStrip?: boolean
}


function SubNavigation({
  appName,
  expired = false,
  fullWidthStrip = false
}: Props) {

  const links: ObjectStringKeyAnyValue = {
    "Temperature logging": [
      {
        "label": "All fridges",
        "link": "/fridges"
      },
      {
        "label": "Add a fridge",
        "link": "/fridges/add-edit"
      },
      {
        "label": "Add sticker pack",
        "link": "/add-sticker-pack"
      },
      {
        "label": "Notifications",
        "link": "/notifications"
      },
    ],
    "Checklists":
      [{
        "label": "All checklists",
        "link": "/checklists"
      },
      {
        "label": "Daily food diary",
        "link": "/daily-checklist-summary"
      },
      {
        "label": "Build new checklist",
        "link": "/checklists/new/settings"
      },
      {
        "label": "Add checklist template",
        "link": "/checklists/presets"
      },
      {
        "label": "Options",
        "link": "/checklist-options"
      }
      ]
  }

  const {
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)
  const pathName = window.location.pathname

  return <div className={`w-full flex flex-row justify-center
    ${fullWidthStrip ? ' bg-brandblue ' : ''}
  `}>
    <div className={`max-w-3xl w-full ${fullWidthStrip && `py-2 px-5`}`}>
      <div className={`w-full flex flex-col gap-2`}>
        {!fullWidthStrip && <h3 className={`text-xl  font-bold font-righteous text-brandblue`}
        > {appName}  {expired && <>[Subscription Expired]</>}</h3>}

        <div className={`w-full flex flex-row flex-wrap gap-2 items-center`}>
          {!fullWidthStrip && <img src='/gearicon.svg' className={`w-5`} />}
          {links[appName] && links[appName].map((linkObject: ObjectStringKeyAnyValue, index: number) => {
            const isLastLink = links[appName].length === (index + 1)
            const isCurrentLink = pathName === linkObject['link']
            return <div key={index}
              className={`${fullWidthStrip ? `text-white ` : `text-gray-700`} hover:opacity-80 flex flex-row flex-wrap gap-2`}
            >
              {expired ?
                <p className={`${pathName === linkObject['link'] && 'text-bold'}`}>{linkObject['label']}</p>
                :
                <Link to={linkObject['link']} className={`${isCurrentLink && 'font-bold'}`}>{linkObject['label']}</Link>
              }
              <p className={`${fullWidthStrip && `text-white`}`}>{!isLastLink && " | "}</p>
            </div>
          })}
        </div>
      </div>
    </div>
  </div>
}
export default SubNavigation