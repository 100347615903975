import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from "react-router-dom"
import LoginRedirect from './LoginRedirect'
import ListAssets from '../asset-management/ListAssets'
import Spinner from '../ui-elements/Spinner'
import Card from '../ui-elements/Card'
import Button from '../formfields/Button'
import TemperatureLogging from '../fridges/TemperatureLogging'
import { table } from 'console'
import SubNavigation from './SubNavigation'
import ChecklistDashboard from './ChecklistDashboard'
import DailyChecklistSummary from './DailyChecklistSummary'


type Props = {
}

function Dashboard({
}: Props) {

  const {
    tableData,
    loginUrl,
    activeFeatures,
    expiredFeatures,
    loggedIn
} = useContext(GlobalContext)



  return <div className={`flex flex-col items-center`}>
    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>

      <h2
        data-testid={'heading-text'}
        className={`text-3xl  font-bold font-righteous font-brandblue`}
      >Dashboard</h2>


      {loggedIn === 'pending' && <Spinner><p>Loading...</p></Spinner>}

      {loggedIn === false && <LoginRedirect loginUrl={loginUrl} />}

      {loggedIn === true && tableData &&
        <div className={`w-full flex flex-col gap-12`}>

 

          {Object.keys(activeFeatures).includes('Checklists') && <div>
            <SubNavigation
              expired={Object.keys(expiredFeatures).includes('Checklists') ? true : false}
              appName={'Checklists'}
            />
            <ChecklistDashboard
            />
          </div>}

          {Object.keys(activeFeatures).includes('Fridges') && <div>
            <SubNavigation
              expired={Object.keys(expiredFeatures).includes('Fridges') ? true : false}
              appName={'Temperature logging'}
            />
            <TemperatureLogging
            />
          </div>}

        </div>
      }



    </div>
  </div>
}
export default Dashboard