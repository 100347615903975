import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { useNavigate, Link } from 'react-router-dom'
import { validateForm } from '../utils/validateForm'
import { subscribe } from "../utils/pubsub"
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import Card from '../ui-elements/Card'
import Text from '../formfields/Text'
import Number from '../formfields/Number'
import Button from '../formfields/Button'
import EnabledDisabled from '../formfields/EnabledDisabled'
import Dropdown from '../formfields/Dropdown'
import Checkbox from '../formfields/Checkbox'
import SubNavigation from './SubNavigation'




type Props = {

}

type OptionsProps = {
  userData: ObjectStringKeyAnyValue | null
  formFields: ObjectStringKeyAnyValue
  fieldName: string
  setFormFields: React.Dispatch<React.SetStateAction<any>>
}


function Options({
  setFormFields,
  formFields,
  fieldName,
  userData
}: OptionsProps) {


  const thisField = formFields[fieldName]
  const optionValues = [{
    "value": 'Everyone',
    "label": 'Everyone'
  }, {
    "value": 'Owners',
    "label": 'Owners'
  }, {
    "value": 'Admins',
    "label": 'Admins'
  },
    // {
    //   "value": `${userData ? userData['cognito:username'] : 'none'}`,
    //   "label": `Just me`
    // }
  ]

  const labels: ObjectStringKeyAnyValue = {
    "fridgesNotCheckedOnTime": 'Fridges haven’t been checked on time',
    "allFridgesChecked": 'All fridges checked ok at end of check period',
    "checkPeriodFinishedWithIssues": 'Check period finished with issues',
    "immediateIssueWarning": 'Immediate notification whenever a reading is out of range or has a note'
  }

  const handleCheckbox = (key: string, isChecked: any) => {
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
    newFieldValues[key]['enabled'] = isChecked
    if (!isChecked) {
      newFieldValues[key]['recipients'] = null
    }
    delete newFieldValues[key]['isValid']
    setFormFields(newFieldValues)
  }

  const handleRadio = (key: string, value: any) => {
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
    newFieldValues[key]['recipients'] = value
    setFormFields(newFieldValues)
  }


  return <Card
    fullwidth={true}
  >
    <div className={`w-full flex flex-row justify-between gap-2`}>
      <p className={`font-bold`}>{labels[fieldName]}</p>

      <EnabledDisabled
        handleChange={handleCheckbox}
        fieldName={fieldName}
        enabled={thisField.enabled ? true : false}
      />
    </div>

    {thisField.enabled && optionValues && <div className={`flex flex-col gap-2 text-sm`}>
      <p className={``}>Who should receive these notifications?</p>
      <div className={`flex flex-row gap-3 text-sm`}>
        {optionValues.map((option, index) => {
          return <label
            key={index}
            className={`flex flex-row gap-1 items-center`}>
            <input
              type={'radio'}
              key={index}
              name={fieldName}
              value={option.value}
              checked={thisField.recipients === option.value ? true : false}
              onChange={() => handleRadio(fieldName, option.value)}
            />
            {option.label}</label>
        })}</div>
    </div>}

    {thisField && thisField['isValid'] === false && <p
      className={`text-xs text-red-700`}>Please select who should receive the notifications</p>}
  </Card>
}




function Notifications({

}: Props) {


const {
  tableData,
  setTableData,
  socket,
  sendMessageToWebsocket,
  idToken,
  accessToken,
  orgSettings,
  currentOrganisation,
  userData
} = useContext(GlobalContext)


  const [savingProgress, setSavingProgress] = useState(false)
  const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue | null>(null)


  const defaults: ObjectStringKeyAnyValue = {
    "fridgesNotCheckedOnTime": {
      "enabled": true,
      "recipients": `Everyone`
    },
    "allFridgesChecked": {
      "enabled": false,
      // "recipients": `Everyone`
    },
    "checkPeriodFinishedWithIssues": {
      "enabled": true,
      "recipients": `Admins`
    },
    "immediateIssueWarning": {
      "enabled": false,
      // "recipients": `Admins`
    }
  }


  useEffect(() => {
    const newFormFields: ObjectStringKeyAnyValue = {}

    Object.keys(defaults).forEach((key) => {
      if (tableData && tableData.Organisation && tableData.Organisation.Notifications && tableData.Organisation.Notifications[key]) {
        newFormFields[key] = tableData.Organisation.Notifications[key]
      } else {
        newFormFields[key] = defaults[key]
      }
    })

    setFormFields(newFormFields)
    // if (tableData && tableData.Organisation && tableData.Organisation.Notifications) {
    //   setFormFields(tableData.Organisation.Notifications)
    // }
  }, [tableData])

  const validateForm = () => {
    console.log('validating form')
    if (formFields) {
      let canSubmit = true
      const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
      Object.keys(formFields).forEach((fieldName: string) => {
        const thisField = formFields[fieldName]
        console.log(fieldName)
        console.log(thisField)
        if (thisField.enabled === true && !thisField.recipients) {
          newFieldValues[fieldName]['isValid'] = false
          canSubmit = false
        } else {
          newFieldValues[fieldName]['isValid'] = true
        }
      })
      setFormFields(newFieldValues)
      if (canSubmit) {
        console.log('ok')
        saveData()
      } else {
        console.log('error')
      }
    }
  }

  const saveData = () => {
    setSavingProgress(true)
    const values = { ...formFields }
    const payload = JSON.stringify({
      action: "notificationPreferences",
      values: values
    })
    sendMessageToWebsocket(payload)

    const unsubscribeUpdate = subscribe("notificationPreferencesResponse", data => {
      setSavingProgress(false)
      unsubscribeUpdate()
    })
  }

  // console.log(tableData)
  // console.log(formFields)

  return <div className={`flex flex-col items-center`}>
    <SubNavigation
      appName={'fridges'}
      fullWidthStrip={true}
    />
    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>
      <h2 className={`text-3xl  font-bold font-righteous font-brandblue`}
      >Notifications</h2>


      {tableData && userData && userData['cognito:username'] ? <div
        className={`w-full flex flex-col items-start gap-5`}>


        <p>Set notifications for when:</p>
        <div
          className={`w-full flex flex-col gap-2`}>
          {formFields && Object.keys(formFields).map((fieldName, index) => {
            return <Options
              key={index}
              formFields={formFields}
              setFormFields={setFormFields}
              userData={userData}
              fieldName={fieldName}
            />
          })}
        </div>
        <Button
          text={'Save preferences'}
          onClick={validateForm}
          size={'big'}
          fullwidth={false}
        />


      </div>
        :
        <Spinner><p>Loading...</p></Spinner>}

      {/* <p className={`text-xs`}>{JSON.stringify(formFields).replaceAll(',', ', ')}</p> */}

      {savingProgress && <Modal
        showCloseButton={true}
        setShowModal={setSavingProgress}
      ><Spinner><p>Saving...</p></Spinner>
      </Modal>}


    </div>
  </div>
}
export default Notifications