

import React, { useContext, useState } from 'react'
import { GlobalContext } from '../GlobalContext'
import CloudformationOutputs from '../CloudformationOutputs.json'
import Modal from '../ui-elements/Modal'

type Props = {
  questionAnswer: any
  isTemperature?: boolean
  size?: 'normal' | 'big'
}

function FormatQuestionAnswer({
  questionAnswer,
  isTemperature = false,
  size = 'normal'
}: Props) {

  const {
    tableData,
    currentOrganisation
  } = useContext(GlobalContext)

  const [showPhotoModal, setShowPhotoModal] = useState(false)

  const classString = `
  ${size === 'normal' ? ' text-sm ' : '' }
  ${size === 'big' ? ' text-xl font-righteous text-brandblue ' : '' }
  `

  return <p className={classString}>

    {questionAnswer.Answer instanceof Array && <>{questionAnswer.Answer && questionAnswer.Answer.map((option: string, index: number) => {
      return `${option}${index + 1 < questionAnswer.Answer.length ? ', ' : ''}`
    })}</>}

    {typeof questionAnswer.Answer === 'string' ? `${questionAnswer.Answer}${isTemperature ? '°' : ''}` : ''}
  </p>
}

export default FormatQuestionAnswer